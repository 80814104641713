.corEmail {
    color: #076EE9;
}

.btnEntrarNovo:hover {
    background-color: #3ACAD3;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.paragrafoOk {
    font-weight: 550;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    color: #1F252E;
}