.fa-pulse {
  -webkit-animation: pulse 1s alternate infinite ease-in-out;
  animation: pulse 1s alternate infinite ease-in-out;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: rotate(8deg);
  }
  to {
    -webkit-transform: rotate(-8deg);
  }
}

@keyframes pulse {
  from {
    transform: rotate(8deg);
  }
  to {
    transform: rotate(-8deg);
  }
}

.sinoC {
  font-size: 32px;
  color: #f7d128b7;
  font-weight: bold;
}

.sinoS {
  font-size: 32px;
  color: #0f182e;
}

#notif .dropdown-menu {
  width: 300px;
}

#notif .dropdown-item:hover {
  width: 100%;
  background-color: #f2f2f2;
  cursor: default !important;
}

.cle {
  clear: both;
}

.tituloNotify {
  font-size: 16px;
  color: #0f182e;
  font-weight: bold;
}

.pNotify {
  text-align: center;
  font-size: 14px !important;
}

.dropdown-menu.show {
  padding: 0 2px 2px 1px;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

#notif .dropdown-toggle::after {
  display: none !important;
}
