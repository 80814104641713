.btn-danger-new,
.btn-info-new {
    background-color: transparent;
    border: none;
}

.thead-light-new {
    background-color: #F8F8F8;
    margin: 20px !important;
    border-radius: 7px;
}

.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}

.itemTabela {
    font-size: 16px;
    color: #4a555f;
    font-weight: 500;
    opacity: 0.9;
}

.borda {
    opacity: 0.5;
    border-bottom: 1px solid #ADB5BD !important;
    margin: 12px;
}

.textDark {
    color: #000 !important;
}

.btn-danger-new-modal {
    background: #fddddd;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #EC5353;
    padding: 15px 40px;
}

.btn-warning-new {
    background: #F6F6F6;;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #878F99;
    padding: 15px 40px;
}

.widHR {
    width: 100% !important;
}

.buttonNovaPeticao,
.buttonNovaPeticao:hover {
    background: #3ACAD3;
    border: 1px solid #3ACAD3;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 7px 38px;
    cursor: pointer;
    font-weight: 700;
}

.buttonAtualizarListagem,
.buttonAtualizarListagem:hover {
    background: #FFFFFF;
    border: 1px solid #3ACAD3;
    border-radius: 3px;
    color: #3ACAD3;
    font-size: 14px !important;
    text-align: center;
    padding: 7px 38px;
    cursor: pointer;
    font-weight: 700;
}

.bgSelected {
    background-image: url("../assets/imagens/icones/select.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff !important;
}

.bgSelected a {
    color: #fff !important;
}

.totalItens {
    font-weight: 500;
    font-size: 15.6667px;
    color: #97A4B1;
}

.btnNoBack {
    background: transparent;
}

.autorizadoClass {
    font-weight: 600;
    font-size: 14px;
    color: #3ACAD3;
}

.inativoClass {
    font-weight: 600;
    font-size: 14px;
    color: #EC5353;
}

.abertaClass {
    font-weight: 600;
    font-size: 14px;
    color: #F47920;
}

.finalizadaClass {
    font-weight: 600;
    font-size: 14px;
    color: #6A7C8D;
}

.noBorder {
    background: transparent;
}

.autorizado {
    font-weight: 700;
    font-size: 14px;
    color: #3ACAD3;
}

.recusado {
    color: #EC5353;
    font-weight: 700;
    font-size: 14px;
}

.pendente {
    color: #FFB600;
    font-weight: 700;
    font-size: 14px;
}