/* Configurações de css do footer */

/* Azul claro: 165ca6 
   Azul escuro: 0f182e
   Cinza: f2f2f2
*/

.fundoFooter {
  /* background-color: #0f182e; */
  background-image: url(../assets/imagens/fundoFoo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-family: "Roboto-Medium";
}

.txtNew {
  font-family: "Roboto-Medium";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ADB5BD;
}

.cursorRodape {
  cursor:  pointer !important;
}

.mail {
  color: #fff;
}

.pRoda {
  color: #808080;
  font-family: "Roboto-Medium";
}

#rodape {
  font-family: "Roboto-Regular";
}

.pRoda:hover {
  color: #daa520;
  cursor: default;
  font-weight: bold;
}

.ulBaixo {
  list-style: none;
}

.ulLine {
  display: inline;
}

.redeSociais {
  margin-left: -40px;
}

.listDotted {
  list-style: square;
}

.redeSociais li {
  padding: 2px;
}

.btPoli {
  margin-top: -15px;
}

/* .tituloFooter {
  color: #daa520;
} */

#rodape .fa-envelope {
  font-size: 18px;
  color: #daa520;
}

.tFooter {
  color: #deb887;
}

#rodape .fa-phone-square {
  font-size: 20px;
  color: #daa520;
}

#rodape .fa-envelope:hover,
#rodape .fa-phone-square:hover {
  color: #fff;
  transition: 250ms;
}

#rodape .fa-instagram,
#rodape .fa-facebook-square,
#rodape .fa-youtube-play,
#rodape .fa-whatsapp {
  color: #fff;
  font-size: 36px;
}

#rodape .fa-whatsapp:hover {
  color: #34bd49;
  transition: 250ms;
}

#rodape .fa-youtube-play:hover {
  color: #ff0000;
  transition: 250ms;
}

#rodape .fa-facebook-square:hover {
  color: #4267b2;
  transition: 250ms;
}

#rodape .fa-instagram:hover {
  color: #f88a30;
  transition: 250ms;
}

.fundoCop {
  background-color: #0f182e;
}

/*Media query*/
@media (max-width: 650px) {
  .ulBaixo .mail-juri a {
    font-size: 14px;
    font-weight: bold;
  }
  .ulBaixo .mail-juri span {
    margin-right: 5px;
  }

  .ulBaixo .mail-tuan span {
    margin-right: 5px;
  }
  .ulBaixo .mail-tuan a {
    font-size: 14px;
    font-weight: bold;
  }
}
