@font-face {
  font-family: "Burt-Bold";
  src: url("./assets/fonts/Burt-Fat.otf");
}

@font-face {
  font-family: "Burt-Medium";
  src: url("./assets/fonts/Burt-Medium.otf");
}

@font-face {
  font-family: "Burt-Regular";
  src: url("./assets/fonts/Burt-Regular.otf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

.robotM {
  font-family: "Roboto-Medium" !important;
}

.robotR {
  font-family: "Roboto-Regular" !important;
}

.robotB {
  font-family: "Roboto-Bold" !important;
}

.tipo {
  font-size: 20px;
  cursor: pointer;
}

.tipo2 {
  cursor: pointer;
}

.bur {
  font-family: "Burt-Bold" !important;
}

.bur2 {
  font-family: "Burt-Medium" !important;
}

.box-shadow {
  -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
}

.box-shadow-invalid {
  -webkit-box-shadow: 1px 2px 7px 0px rgba(255, 2, 2, 0.52);
  -moz-box-shadow: 1px 2px 7px 0px rgba(255, 6, 6, 0.52);
  box-shadow: 1px 2px 7px 0px rgba(241, 2, 2, 0.52);
}

.borda-redonda {
  border-radius: 15px;
}

.campoObrigatorio {
  color: rgb(238, 27, 12);
  font-weight: bold;
  font-size: 16px;
}

.text-error {
  color: rgb(238, 27, 12);
  font-size: 11px;
}

.s-alert-box {
  min-width: 250px;
  border-radius: 10px;
}

.s-alert-close::before,
.s-alert-close::after {
  width: 2px;
}

.s-alert-warning {
  color: #000;
}

.app {
  height: 100vh;
}

.s-alert-box {
  min-width: 250px;
  border-radius: 10px;
  z-index: 999999;
}

.s-alert-close::before,
.s-alert-close::after {
  width: 2px;
}

body {
  background-color: #fff !important;
}

.form-item {
  margin-bottom: 18px;
}

.card {
  border-radius: 10px !important;
  transition: 0.2s;
  color: #000;
  -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  /* box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52); */
  box-shadow: 0px 4px 14px rgba(229, 236, 251, 0.33);
  margin-top: 15px;
  border-bottom: 10px solid #3ACAD3;
}

.box-shadow {
  -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.52);
  border-radius: 10px;
}

.card:hover {
  margin-top: -5px;
  transition: 0.2s;
  color: rgba(14, 107, 34, 0.863);
}

.fa-icone-card {
  font-size: 3rem;
  margin-bottom: 15px;
}

.display-none {
  display: none;
}

a:hover {
  text-decoration: none !important;
}

.top--5 {
  margin-top: -5px;
}

.invalid {
  border-color: red !important;
}

.valid {
  border-color: rgba(14, 107, 34, 0.863) !important;
}

.container-load {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255, 0.3);
  padding-top: 20%;
  padding-left: 45%;
  z-index: 999;
}

.no-li-decoration {
  list-style-type: none;
}

.li-border {
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  color: rgb(24, 129, 3);
  cursor: pointer !important;
  margin-left: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin-top: 5px;
  padding-top: 7px;
}

.li-border a {
  cursor: pointer !important;
  color: #828C99;
  font-weight: 700;
}

.li-border:hover {
  transform: scale(1.1);
  transition: 0.3s;
  font-weight: bold;
  cursor: pointer !important;
}

.active-page {
  background-color: #14162d;
  color: #fff !important;
}

.link-page {
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  color: rgb(24, 129, 3);
  cursor: pointer !important;
  margin-top: 8px;
}

/* .link-page:hover {
  transform: scale(1.1);
  transition: 0.3s;
  font-weight: bold;
  cursor: pointer !important;
  background-color: #f2f2f2;
} */

.disable-page {
  color: #5f5f5f;
  cursor: pointer !important;
}

.fa-toggle-on {
  color: #165ca6;
  font-size: 35px !important;
}

.fa-toggle-off {
  color: rgb(170, 19, 9);
}

.fa-toggle-off,
.fa-toggle-on {
  font-size: 35px !important;
}

.fa-trash,
.fa-pencil {
  font-size: 18px;
}

.btAtivar {
  background-color: transparent !important;
}

div .dropdown-item {
  cursor: pointer !important;
}

.box-confirmation {
  background-color: #fff;
  border-radius: 10px;
}

.image-assinatura {
  transform: rotate(90deg);
  width: 200px;
  height: 500px;
  margin-top: -130px;
  margin-bottom: -130px;
}

.sair {
  color: #e9e5e5 !important;
}

.sair:hover {
  color: rgb(253, 249, 7) !important;
}

.margimB {
  margin-bottom: 10px;
}

h3 {
  margin-top: 25px !important;
}

.borda-redonda {
  border-radius: 15px;
}

.loader-modal {
  margin-left: 40%;
  margin-top: 20%;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

::-webkit-scrollbar {
  width: 6px;
  background: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  /* background: #0f182e; */
  background: linear-gradient(83.27deg, #053062 17.56%, #41F5D1 104.3%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.acoes-edicao {
  position: relative;
  margin-right: 5px !important;
}

.box-confirmation p {
  font-weight: bold !important;
}

.container-galeria {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.pin-evento {
  color: #66bb6a;
}

.pin-evento i {
  font-size: 20px;
}

@media screen and (max-width: 1200px) {
  .container-load {
    padding-top: 30%;
    padding-left: 40%;
  }
}

@media screen and (max-width: 800px) {
  .container-load {
    padding-top: 40%;
    padding-left: 40%;
  }
}

@media screen and (max-width: 500px) {
  .container-load {
    padding-top: 60%;
    padding-left: 30%;
  }
}

/* step zila */

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.large-screen .container {
  min-height: 83vh;
  margin-left: 1%;
  margin-right: 1%;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

div.footer-buttons {
  display: none;
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #077716;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #077716;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

/* Estiliza as bolinhas dos steps */

ol.progtrckr li:before {
  position: relative;
  bottom: -3.6rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\2022";
  color: white;
  background-color: #cccccc;
  width: 2em;
  line-height: 2em;
  border-radius: 4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: white;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #077716;
  width: 2em;
  line-height: 2em;
  border-radius: 4em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: white;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #1b7615;
  width: 2em;
  line-height: 2em;
  border-radius: 4em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.container-steps {
  display: flex;
  justify-content: center;
  margin: 50px;
}

@media (max-width: 2560px) {
  .progtrckr li span {
    display: none;
  }
  .progtrckr em {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 180px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 180px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 180px;
  }
  ol.progtrckr li:before {
    left: 35%;
  }
  .container-steps {
    margin: 20px;
  }
}

@media (max-width: 1700px) {
  .progtrckr li span {
    display: none;
  }
  .progtrckr em {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 150px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 150px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 150px;
  }
  ol.progtrckr li:before {
    left: 45%;
  }
  .container-steps {
    margin: 20px;
  }
}

@media (max-width: 960px) {
  .progtrckr li span {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 130px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 130px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 130px;
  }
  ol.progtrckr li:before {
    left: 42%;
  }
  .container-steps {
    margin: 15px;
  }
}

@media (max-width: 720px) {
  .progtrckr li span {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 100px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 100px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 100px;
  }
  ol.progtrckr li:before {
    left: 40%;
  }
  .container-steps {
    margin: 15px;
  }
}

@media (max-width: 540px) {
  .progtrckr li span {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 80px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 80px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 80px;
  }
  ol.progtrckr li:before {
    left: 37%;
  }
  .container-steps {
    margin: 10px;
  }
}

@media (max-width: 360px) {
  .progtrckr li span {
    display: none;
  }
  ol.progtrckr li.progtrckr-todo {
    width: 65px;
  }
  ol.progtrckr li.progtrckr-doing {
    width: 65px;
  }
  ol.progtrckr li.progtrckr-done {
    width: 65px;
  }
  ol.progtrckr li:before {
    left: 32%;
  }
  .container-steps {
    margin: 10px;
  }
}

.footer {
  margin-bottom: 0px !important;
}

.titulo-pagina h1 {
  font-size: 38px;
  font-weight: bold;
}

.titulo-pagina h1 span {
  font-size: 30px;
  font-weight: normal;
}

.card-pagina {
  color: rgb(63, 63, 63);
}

.card-pagina:hover {
  color: rgb(8, 8, 8);
}

.loading {
  position: absolute !important;
  width: 100% !important;
  height: 100vh !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  z-index: 99999 !important;
  display: flex;
  text-align: center !important;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
}

.container-busca-rol {
  overflow: hidden;
  max-height: 80vh;
  min-height: 65vh;
}

.container-jurisprudencia{
  /* display: block; */
  overflow: scroll;
  margin: -25px;
  height: 100%;
  min-height: 20vh;
  background-color: #F8F8F8;
  border-radius: 7px;
  /* width: 477px;
  height: 338px; */
}

.container-jurisprudencia2{
  /* display: block; */
  overflow: scroll;
  margin: -25px;
  height: 60vh;
  min-height: 20vh;
  background-color: #F8F8F8;
  border-radius: 7px;
  /* width: 477px;
  height: 338px; */
}

.container-jurisprudencia-modal-juris{
  /* display: block; */
  overflow: scroll;
  max-height: 50vh;
  min-height: 40vh;
  background-color: #F8F8F8;
  border-radius: 7px;
  /* width: 477px;
  height: 338px; */
}

.container-jurisprudencia-sem{
  /* display: block; */
  background-color: #F8F8F8;
  border-radius: 7px;
  width: auto;
  /* width: 477px;
  height: 338px; */
}

.container-jurisprudencia span {
  font-size: 14px;
  line-height: 155%;
  /* or 22px */
  align-items: center;
  color: #363A40;
}

.contariner-paginacao {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
