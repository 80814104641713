.popup {
    top: 55px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.popupinner {
    position: relative;
    padding: 2vw;
    width: 100%;
    height: 615px;
    background-color: white;
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 0.95vw;
    top: 0;
    border-radius: 10px;
}