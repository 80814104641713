.container {
  width: calc(100% - 28px);
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 3px;
  position: absolute;
  z-index: 10000000;
  background-color: white;
  user-select: none;
}

.content {
  width: 100%;
  /* background-color: rgba(255, 0, 0, 0.195); */
  display: flex;
  flex-direction: column;
  color: #495057;
  gap: 10px;
}

.button {
  background-color: white;
  min-height: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #495057;
  padding: 0 7px;
  cursor: pointer;
  z-index: 2;
}

.options {
  display: flex;
  flex-direction: column;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding: 6px 5px;
  margin-top: -1px;
}

.checks {
  display: flex;
  align-items: center;
  gap: 5px;
}

.numberSelected {
  background-color: #7c838a;
  color: white;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}