.accordion .card{
    color: #808080 !important;
    font-weight: bold !important;
    border-bottom: 1px solid #fff !important;
}

.buttonPagar {
    background: #3ACAD3;
    border: 1px solid #3ACAD3;
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 7px 38px;
    cursor: pointer;
}

.borderDetalhe {
    border-bottom: 0.1px solid #808080;
}