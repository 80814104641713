.widHR {
    width: 100% !important;
}

.btnRolsPesquisar {
    background: #3ACAD3;
    border: 1px solid #3ACAD3;
    border-radius: 3px;
    color: #FFFFFF;
    cursor: pointer;
    /* height: 42px;
    width: 158px; */
    padding: 10px 20px;
}

.resultsEncontrados {
    width: 472px;
    height: 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 154%;
    /* or 22px */
    display: flex;
    align-items: center;
    color: #6A7C8D;
    margin-top: -7px;
}

.btnCopiar {
    background-color: #F8F8F8;
}

.fontNegrito {
    font-weight: 700;
}

.color {
    color: #000 !important;
}

.pAcao {
    height: 51px;
    font-weight: 400;
    font-size: 14px;
    line-height: 154%;
    align-items: center;

    /* #6A7C8D */

    color: #6A7C8D;
}

.btnPopup {
    top: 0px;
    right: 0px;
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 22px;
    box-sizing: border-box;
    background: none #fdfdfd;
    background: linear-gradient(180deg, #fdfdfd 0, #f6f7f8);
    border: 1px solid #999;
    border-radius: 2px;
    color: #333;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    white-space: nowrap;
    font-family: Arial, Helvetica, sans-serif;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
    z-index: 100;
}

.btnGptPopup {
    top: 0px;
    right: 0px;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 2px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 25px;
    cursor: pointer;
    white-space: nowrap;
    font-family: Arial, Helvetica, sans-serif;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0;
    z-index: 100;
    color: white;
}

.popup-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    background-color: red;
    position: absolute;
    line-height: 22px;
    box-sizing: border-box;
    background: none #fdfdfd;
    background: linear-gradient(180deg, #fdfdfd 0, #f6f7f8);
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    font-family: inherit;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.popup-title {
    font-size: 15px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin-top: 3px;
    margin-left: 10px;
}

.adjust-size {
    margin-top: -190px;
}

.popup-header2 {
    top: -190px;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    background-color: red;
    position: absolute;
    line-height: 22px;
    box-sizing: border-box;
    background: none #fdfdfd;
    background: linear-gradient(180deg, #fdfdfd 0, #f6f7f8);
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    font-family: inherit;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.numberSelected {
    background-color: #3acad3;
    color: white;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 22px;
}

.paginacao {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 99%;
    height: 38px;
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: 3;
}